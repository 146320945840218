import React, { FC, useEffect } from 'react'
import { Box, Grid } from '@mui/material'
import { Button } from 'gatsby-material-ui-components'
import useForm, { ValidationRule } from '@hooks/useForm'
import {
  FormAddCredentials,
  initialValues,
  initialValidation,
  TFormEditCredentials
} from '@components/Form/FormAddCredentials'
import {
  CredentialResponse,
  getCredential,
  updateCredential
} from '@utils/api/credentials'
import GenericFormPage from '@templates/Generic/Form'
import useApi from '@hooks/useApi'
import { PageProps } from 'gatsby'
import { Alert } from '@mui/material'
import { ApiResponse } from '@utils/api'

const HostsCredentialsEditPage: FC<PageProps> = ({ params }) => {
  const getApi = useApi<CredentialResponse>({
    apiMethod: getCredential,
    params: params.id
  })

  const saveApi = useApi<ApiResponse<string>>({
    apiMethod: updateCredential,
    requestOnMount: false
  })

  const { response, loading } = getApi

  const form = useForm<TFormEditCredentials>({
    initialValues: {
      ...initialValues,
      credentials_name: params.id
    },
    initialValidationRules:
      initialValidation as ValidationRule<TFormEditCredentials>
  })

  useEffect(() => {
    if (response) {
      form.setValues({
        ...initialValues,
        credentials_name: response.credentials_id,
        credentials_type: response.credentials_type
      })
    }
  }, [response])

  return (
    <GenericFormPage
      awaitingData={loading}
      title={`Edit ${params.id}`}
      breadcrumbs={[
        {
          title: 'Hosts',
          to: '/hosts/'
        },
        {
          title: 'Credentials',
          to: '/hosts/credentials/'
        },
        {
          title: `Edit ${params.id}`
        }
      ]}
      form={form as any}
      api={saveApi}
      formActions={
        <Grid container spacing={2}>
          <Grid item>
            <Button
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading || saveApi.loading}
            >
              {'Update Credentials'}
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="text"
              onClick={() => window !== undefined && window.history.back()}
            >
              {'back'}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Box mb={3} width={'100%'}>
        <Alert severity={'info'}>
          Credential data is encrypted and can't be displayed. Complete the form
          below to replace the existing credential.
        </Alert>
      </Box>
      <FormAddCredentials form={form as any} edit={true} />
    </GenericFormPage>
  )
}

export default HostsCredentialsEditPage
